<template>
  <div class="pay">
    <BaseHeaderBar
      title="Kode pembayaran"
      :showCustomerService="false"
      :showBackButton="true"
    />
    <div class="pay-amount">
      <p class="label">Jumlah pembayaran</p>
      <p class="amount">Rp{{ formatNumberWithCommas(payInfo.padryAmount) }}</p>
    </div>
    <div class="pay-info-list">
      <div class="info">
        <p class="label">Nama Bank</p>
        <p class="value">{{ payInfo.padryChannel }}</p>
      </div>
      <div class="info">
        <p class="label">Metode pembayaran</p>
        <p class="value">{{ payInfo.padryWay }}</p>
      </div>
      <div class="info">
        <p class="label">Masa berlaku</p>
        <p class="value">{{ payInfo.lodrseTime }}</p>
      </div>
      <div
        class="info"
        v-clipboard:copy="payInfo.padrymentCode"
        v-clipboard:success="onCopySuccess"
        v-clipboard:error="onCopyError"
      >
        <p class="label">
          Kode pembayaran
          <img class="copy-btn" src="@/assets/img/copy-btn.jpg" />
        </p>
        <p class="value">{{ payInfo.padrymentCode }}</p>
      </div>
    </div>
    <div class="extra-info">
      <div class="tabs">
        <div
          :class="activate == 1 ? 'tab activate_tab' : 'tab'"
          @click="activate = 1"
        >
          Hal yang perlu diperhatikan
        </div>
        <div
          :class="activate == 2 ? 'tab activate_tab' : 'tab'"
          @click="activate = 2"
        >
          Cara Pembayaran
        </div>
        <img src="@/assets/img/zhuyishixiang_fenge.png" alt="" />
      </div>
      <div class="tab-content" v-show="activate == 1">
        <p v-for="(item, index) in payInfo.prdrecautions.step" :key="index">
          {{ item }}
        </p>
      </div>
      <div class="tab-content" v-show="activate == 2">
        <p
          v-for="(item, index) in payInfo.redrpaymentProcess[0].step"
          :key="index"
        >
          {{ item }}
        </p>
      </div>
    </div>
    <button type="button" class="paid-btn" @click="onPaid">
      Saya sudah melakukan pembayaran
    </button>
    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
    >
      <div class="repayment-success-dialog">
        <img src="@/assets/img/huankuanchenggong.png" alt="" />
        <p class="tip">Pembayaran berhasil！</p>
        <button
          v-if="payInfo.padryCodeid"
          type="button"
          class="ok-btn"
          @click="onConfirm"
        >
          ok
        </button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs, Dialog, Toast } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import request from '@/utils/request'
import VueClipboard from 'vue-clipboard2'

Vue.use(Tab)
Vue.use(Tabs)
Vue.use(VueClipboard)

export default {
  name: 'Pay',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      payInfo: {
        prdrecautions: {},
        redrpaymentProcess: [{}]
      },
      activate: 1
    }
  },
  created () {
    this.getPayCode()
  },
  methods: {
    getPayCode () {
      const { repayType, amount, orderId, channelId, payChannel, name, payWay, payWayName } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryAmount: amount,
        padryType: repayType,
        padrymentChannel: channelId,
        padryChannel: payChannel,
        padryChannelName: name,
        padryWay: payWay,
        padryWayName: payWayName
      }
      request.post('crdreatePaymentCode', postData)
        .then(res => {
          this.payInfo = res
        })
    },
    onCopySuccess () {
      Toast('Berhasil disalin')
    },
    onCopyError () {
      Toast('Penyalinan gagal')
    },
    onPaid () {
      const { orderId } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryCodeid: this.payInfo.padryCodeid
      }
      request.post('redrpaymentStatus', postData)
        .then(res => {
          if (res.padryStatus === 2) {
            this.visible = true
          } else {
            Toast(res.padryMsg)
          }
        })
    },
    onConfirm () {
      this.onCloseDialog()
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped lang="less">
.pay {
  min-height: 100vh;
  background-color: @body-bg-color;
}

.pay-amount {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 200px;
  margin: 34px;
  margin-bottom: 41px;
  text-align: center;
  background: url("../assets/img/pay_bg.png") no-repeat;
  background-size: 100% 100%;

  .label {
    font-size: 34px;
    font-weight: 400;
    color: #ffffff;
  }

  .amount {
    font-size: 54px;
    font-weight: 600;
    color: #ffffff;
  }
}

.pay-info-list {
  padding: 0 30px;
  margin-left: 34px;
  margin-right: 34px;
  background-color: #8875d0;
  border-radius: 30px;

  .info {
    height: 97px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(147, 130, 213);
    font-size: 28px;
    font-weight: 500;
    color: #fff;
  }
  .info:last-child {
    border: none;
  }

  .label {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .copy-btn {
    width: 30px;
    height: 30px;
    margin-left: 20px;
    display: flex;
    position: absolute;
    right: 20px;
  }

  p {
    font-weight: 600;
  }

  & > .label,
  & > .value {
    font-size: 26px;
    color: #001c44;
  }
}

.extra-info {
  position: relative;
  margin: 36px;
  background: #8875d0;
  border-radius: 30px;
  padding: 20px 30px;
  margin-bottom: 50px;
  height: 446px;

  .tabs {
    position: relative;
    display: flex;
    justify-content: space-between;
    background: #cfc8ec;
    height: 100px;
    border-radius: 50px;
    .tab {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      flex: 1;
      font-size: 32px;
      font-weight: 400;
      color: #a942e6;
    }
    .activate_tab {
      font-weight: 600;
      color: #9432cd;
    }
    img {
      width: 4px;
      height: 35px;
      position: absolute;
      left: 50%;
      top: 33%;
      transform: translate(-50% -50%);
    }
  }
}

.tab-content {
  margin-top: 30px;
  margin-bottom: 30px;
  overflow-y: scroll;
  overflow: auto;
  height: 320px;

  p {
    font-size: 30px;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    word-wrap: break-word;

    &:nth-child(n + 2) {
      margin-top: 16px;
    }
  }
}

.paid-btn {
  .submit-btn;
  width: 650px;
  margin-top: 20px;
  margin-bottom: 80px;
}

.repayment-success-dialog {
  position: relative;
  height: 590px;
  img {
    position: absolute;
    top: -160px;
    left: 50%;
    transform: translateX(-50%);
    width: 501px;
    height: 390px;
  }
}

.tip {
  position: absolute;
  top: 280px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 42px;
  font-size: 36px;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.ok-btn {
  .submit-btn;
  width: 520px;
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
}
</style>
